/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from './footer'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      
  file(relativePath: {eq: "logo.png"}) {
     childImageSharp {
      fluid(maxWidth: 200) {
        src
      }
    }
  }
    }
  `)

  return (
    <div>
    <div className="site-wrapper">
      <Header siteTitle={data.site.siteMetadata.title} img={data.file.childImageSharp.fluid.src} />
   
        <main id="site-main" className="site-main">{children}</main>
        {/* <footer className="site-foot">
          © Asish George
        </footer>
       */}

    </div>
    
      <Footer /> 
      </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
