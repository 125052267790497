
module.exports = {
    pathPrefix: '/',
    url: 'https://asishgeorge.in',
    title: 'Asish George',
    subtitle: 'Let\'s Create Something Awesome!',
    copyright: '© All rights reserved.',
    disqusShortname: '',
    description:'A Central Creative Hub for all experiments done by Asish George',
    postsPerPage: 10,
    googleAnalyticsId: 'G-579JXFEVYK',
    menu: [],
    keywords:['developer', 'web developer', 'ag', 'Asish George', 'coding','indian','Indian Coder', 'asish george'],
    author: {
        name: 'Asish George',
        photo: '/photo.jpg',
        bio: 'Engineer by Profession. Artist by Heart.',
        contacts: {
            email: 'mailto:contact.asishgeorge@gmail.com',
            facebook: 'https://www.facebook.com/asish.george.96/',
            telegram: '',
            twitter: 'https://twitter.com/asishgeorg',
            github: 'https://github.com/asishgeorge',
            rss: '/rss.xml',
            vkontakte: '',
            linkedin: 'https://www.linkedin.com/in/asish-george/',
            instagram: 'https://instagram.com/asish.george',
            line: '',
            gitlab: '',
            weibo: '',
            codepen: '',
            youtube: 'https://youtube.com/asishgeorgeoriginals',
            youtube1: 'https://youtube.com/asishgeorgetech',
            soundcloud: '',
        }
    }
};
