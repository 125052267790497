import React from "react"
import Icons from './Icon'
import iconSvg from '../constants/icons' 
import { Link } from "gatsby"
import siteConfig from '../../config'
const Footer = () => {

     return (
        <footer className="site-foot">
          {/* © Asish George */}

            <div className="container  width1 ">
                <div className="row">
                    <div className="col-6 " id="links">
                        <div className="row "> 
                            <Link to={`/`}> <span className="text-accent">Home</span></Link>
                        </div>
                        {/* <div className="row">
                            <Link to={`/contact`}><span className="text-accent">Contact</span></Link>
                        </div> */}
                        
                        <div className="row">
                            <Link to={`/categories`}><span className="text-accent">Categories</span></Link>
                        </div>
                        
                        <div className="row">
                            <Link to={`/tags`}><span className="text-accent">Tags</span></Link>
                        </div>
                        <div className="row">
                            <Link to={`/sitemap.xml`}><span className="text-accent">Sitemap</span></Link>
                        </div>
                        <div className="row">
                            <Link to={`/rss.xml`}><span className="text-accent">RSS</span></Link>
                        </div>
                        

                       
                    </div>
                    <div className="col-6 float-end">
                        
                        <a href={siteConfig.author.contacts.instagram}  rel="noopener noreferrer" target="_blank">
                        <span className="text-accent" >
                        <Icons name="Instagram" icon={iconSvg.INSTAGRAM}></Icons></span></a>

                        <a href={siteConfig.author.contacts.facebook}  rel="noopener noreferrer" target="_blank">
                        <span className="text-accent" >
                        <Icons name="Facebook" icon={iconSvg.FACEBOOK}></Icons></span></a>
                        
                        <a href={siteConfig.author.contacts.github}  rel="noopener noreferrer" target="_blank">
                        <span className="text-accent" >
                        <Icons name="Github" icon={iconSvg.GITHUB}></Icons></span></a>

                        <a href={siteConfig.author.contacts.email}  rel="noopener noreferrer" target="_blank">
                        <span className="text-accent" >
                        <Icons name="Email" icon={iconSvg.EMAIL}></Icons></span></a>

                        <a href={siteConfig.author.contacts.youtube}  rel="noopener noreferrer" target="_blank">
                        <span className="text-accent" >
                        <Icons name="YouTube" icon={iconSvg.YOUTUBE}></Icons></span></a>




                    </div>
                </div>
                          
            </div>
            <div className="row">
                    <div className="col-12 text-accent">© Asish George</div>
                </div>

        </footer>
     )
}


export default Footer;