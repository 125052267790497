import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import siteConfig from '../../config';
import '../utils/css/screen.css'
const Header = ({ siteTitle, img }) => {
  
  const [toggleNav, setToggleNav] = React.useState(false);
  return(
  
<div className={` ${toggleNav ? `site-head-open` : `site-head-close`}`}>
  <header className="site-head">
        <div className="site-head-container">
          <a
            className="nav-burger"
            onClick={() => setToggleNav(!toggleNav)}
          >
            <div
              className="hamburger hamburger--collapse"
              aria-label="Menu"
              role="button"
              aria-controls="navigation"
            >
              <div className="hamburger-box">
                <div className="hamburger-inner" />
              </div>
            </div>
          </a>
          <nav id="swup" className="site-head-left">
            <ul className="nav" role="menu">
              <li className="nav-home" role="menuitem">
                <Link to={`/`}>Home</Link>
              </li>
              
              <li className="nav-about" role="menuitem">
                <Link to={`/apps`}>Apps</Link>
              </li>
            
                <li className="nav-elements" role="menuitem">
                <Link to={`/gallery`}>Videos</Link>
              </li>
              <li className="nav-elements" role="menuitem">
                <Link to={`/blog`}>Blog</Link>
             </li>
             
              <li className="nav-elements" role="menuitem">
                <Link to={`/podcasts`}>Podcast</Link>
             </li>
            </ul>
          </nav>
          <div className="site-head-center">
            <Link className="site-head-logo" to={`/`}>
              {/* {siteTitle} */}

              <img src={img} alt="" className="kg-image"></img>
            </Link>
          </div>
          <div className="site-head-right">
            <div className="social-links">

              <a
                href={siteConfig.author.contacts.instagram}
                title="Instagram"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
              <a
                href={siteConfig.author.contacts.youtube}
                title="YouTube"
                target="_blank"
                rel="noopener noreferrer"
              >
                YouTube
              </a>
              
              <a
                href={siteConfig.author.contacts.linkedin}
                title="LinkedIn"
                target="_blank"
                rel="noopener noreferrer"
              >
                LinkedIn
              </a>
              {/* <a
                href="https://twitter.com/asishgeorg"
                title="Twitter"
                target="_blank"
                rel="noopener noreferrer"
              >
                Twitter
              </a>
              <Link
                to={`/rss.xml`}
                title="RSS"
                target="_blank"
                rel="noopener noreferrer"
              >
                RSS
              </Link> */}
              <a
                href={siteConfig.author.contacts.github}
                title="GitHub"
                target="_blank"
                rel="noopener noreferrer"
              >
                GitHub
              </a>
              
                {/* <Link to={`/about`}>Contact</Link>
               */}
            </div>
          </div>
        </div>
      </header>
</div>

)}

Header.propTypes = {
  siteTitle: PropTypes.string,
  img: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``,
  img: ``
}


export default Header
